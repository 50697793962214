import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faPlus, faMagnifyingGlass, faSave } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Comps/Modal';
import { StaticDialog } from 'react-st-modal';

class TjRs extends Component {
  constructor(props) {
    super(props);
    this.state = {
    Loader:true,
    modal:false,
    edit:false,
    item:{},
    cartorio:{"nome":"","edrc":"","cidade":"","serventia":""}
    };
    this.iptNome = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptTelefone = React.createRef();
    this.iptCns = React.createRef();
  }

  componentDidMount() {
    document.title = "Rio Grande Do Sul - Consulta";
    fetch(`${apiUrl}/list`+window.location.pathname, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}
      })


}

 
handleChange = (event) => {
  const { name, value } = event.target;
  this.setState((prevState) => ({
    cartorio: {
      ...prevState.cartorio,
      [name]: value,
    },
  }));
};


handleChange2 = (event) => {
  const { name, value } = event.target;
  this.setState((prevState) => ({
    item: {
      ...prevState.item,
      [name]: value,
    },
  }));
};


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    return (
     
      <>
      <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight"> TjRs </h2></div></header>
      <main>
 
   
   
      <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
               <div class="card-header">
            <button class="btn btn-xs btn-primary-2" style={{background:"#dc3545",color:"#fff"}} onClick={()=>{
                this.setState({modal:true})
                return;
                Modal.create({
                    titulo:"Adicionar cartório",
                    html:<>
<div class="card-body">
   <div class="form-group mt-2"><label>Nome do Ofício:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({nomeOfc:e.target.value})
   }}/></div>
   <div class="form-group mt-2"><label>Responsável:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Resp:e.target.value})
   }}/>
   </div>
   <div class="form-group mt-2"><label>Endereço:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Edrc:e.target.value})
   }}/>
   </div>
   <div class="form-group mt-2"><label>Cidade/UF:</label>
   <input type="text" class="form-control form-control-sm"
   onChange={(e)=>{
    this.setState({Cidade:e.target.value})
   }}
   /></div>
   <div class="form-group mt-2"><label>Telefone:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Telefone:e.target.value})
   }}/>

<div class="form-group mt-2"><label>Cns:</label>
   <input type="text" class="form-control form-control-sm" onChange={(e)=>{
    this.setState({Cns:e.target.value})
   }}/>
   </div>
   </div>
</div>

<div class="card-footer">
<button type="submit" style={{background:"#0d6efd"}} class="btn btn-primary float-right ml-2" onClick={()=>{

    
    const id = toast.loading("Enviando...")
 
    fetch(`${apiUrl}${window.location.pathname}/adicionar-cartorio/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        },
        body:JSON.stringify({
        nomeOfc:this.state.nomeOfc,
        Resp:this.state.Resp,
        Edrc:this.state.Edrc,
        Cidade:this.state.Cidade,
        Telefone:this.state.Telefone,
        Cns:this.state.Cns,
        })
      }).then(response => {
      if(response.status == 200){
       
        response.json().then(data=>{
            toast.update(id, { render:"cartório adicionado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
            Modal.close()
            const newItem = {
              id:data.id,
              nome:this.state.nomeOfc,
              resp:this.state.Resp,
              edrc:this.state.Edrc,
              cidade:this.state.Cidade,
              telefone:this.state.Telefone,
              Cns:this.state.Cns
            }
console.log(this.state)
            this.setState((prevState) => ({
                data: {
                  ...prevState.data,
                  cartorio: [...prevState.data.cartorio, newItem]
                }
              }));
       })
       



      }
      })
}}><i class="fas fa-download"></i> Salvar </button>
</div>
                    </>
                })
            }}>  <FontAwesomeIcon icon={faUniversity} /> Adicionar cartório </button></div>
               <div class="card-body">
                  <div>
                 
                  </div>
                  
             
                       {this.state.data.cartorio.length > 0 ? <>
                     <table class="table table-sm table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">#</th>
                           <th scope="col">Nome</th>
                           <th scope="col">Endereço</th>
                           <th scope="col">Serventia</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                       {this.state.data.cartorio.map((item,index)=><>
                        <tr>
                           <td>{index + 1}</td>
                           <td>{item.nome}</td>
                           <td>{item.edrc}</td>
                           <td>{item.codeser}</td>
                           <td>
                              <div class="btn-group btn-group-sm">
                            <button class="btn btn-info" onClick={()=>{
                          
                                this.setState({edit:true,item})
                               return
                                Modal.create({
                                    titulo:"Editar cartório",
                                    html:<>
                <div class="card-body">
                   <div class="form-group mt-2"><label>Nome do Ofício:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptNome} /></div>
                   <div class="form-group mt-2"><label>Responsável:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptResp}/>
                   </div>
                   <div class="form-group mt-2"><label>Endereço:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptEdrc}/>
                   </div>
                   <div class="form-group mt-2"><label>Cidade/UF:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptCidade}/></div>
                   <div class="form-group mt-2"><label>Telefone:</label>
                   <input type="text" class="form-control form-control-sm" ref={this.iptTelefone}/>
                   </div>
                   <div class="form-group mt-2"><label>Cns:</label>
                   <input type="text" class="form-control form-control-sm"  ref={this.iptCns}/>
                   </div>
                </div>
                
                <div class="card-footer">
                
                <button type="submit" style={{background:"#0d6efd"}} class="btn btn-primary float-right ml-2" onClick={()=>{
                    const id = toast.loading("Enviando...")
                   fetch(`${apiUrl}${window.location.pathname}/atualizar-cartorio/${item.id}`, {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer '+Cookies.get("auth_token"),
                        },
                        body:JSON.stringify({
                        nomeOfc:this.iptNome.current.value,
                        Resp:this.iptResp.current.value,
                        Edrc:this.iptEdrc.current.value,
                        Cidade:this.iptCidade.current.value,
                        Telefone:this.iptTelefone.current.value,
                        Cns:this.iptCns.current.value
                        })
                      }).then(response => {
                      if(response.status == 200){
                       
                       response.json().then(data=>{

                        toast.update(id, { render:"Cartório atualizado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
                      
                        this.setState((prevState) => ({
                            data: {
                              ...prevState.data,
                              cartorio: prevState.data.cartorio.map(i =>
                                i.id === item.id ? { ...i,
                                nome: this.iptNome.current.value,
                                resp:this.iptResp.current.value,
                                edrc:this.iptEdrc.current.value,
                                cidade:this.iptCidade.current.value,
                                telefone:this.iptTelefone.current.value,
                                Cns:this.iptCns.current.value
                            } : i
                              )
                            }
                          }));
                        Modal.close()
                       })
                       
                
                
                
                      }
                      })
                }}><i class="fas fa-download"></i> Atualizar </button>
                </div>
                                    </>
                                 })

                                 this.iptNome.current.value = item.nome;
                                 this.iptResp.current.value = item.resp;
                                 this.iptEdrc.current.value = item.edrc;
                                 this.iptCidade.current.value = item.cidade;
                                 this.iptTelefone.current.value = item.telefone;
                                 this.iptCns.current.value = item.Cns
                            }}>
                            <FontAwesomeIcon color='#fff' icon={faPen} />
                            </button>
                            <button  class="btn btn-danger" onClick={()=>{
                            Modal.create({
                                titulo:"Tem certeza?",
                                html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                 const id = toast.loading("Carregando...")
                                 fetch(`${apiUrl}${window.location.pathname}/remover-cartorio/${item.id}`, {
                                    method: 'DELETE',
                                    credentials: 'include',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                    }
                                  }).then(response => {
                                  if(response.status == 200){
                                    toast.update(id, { render:"Cartório removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});

                                    this.setState((prevState) => ({
                                        data: {
                                          ...prevState.data,
                                          cartorio: prevState.data.cartorio.filter(i => i.id !== item.id)
                                        }
                                      }));
                                  Modal.close()

                                  }
                                  })



                                }}><i class="fas fa-download"></i> Remover </button></div>
                                </>
                            })
                            }}>
                            <FontAwesomeIcon icon={faTrash} />
                            </button>
                            </div>
                           </td>
                        </tr>
                       </>)}
                       </tbody>
                  </table>
                       </> : <>
                       Não há dados cadastrados!
                       </>}

            

                
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
<div class="card-header d-flex justify-content-between">


  <div className='area-buscas'>
  <Link class="btn btn-xs btn-primary-2" style={{background:"#dc3545",color:"#fff"}} to={`${window.location.pathname}/create`}>
  <FontAwesomeIcon icon={faPlus} /> Adicionar certidão
  </Link>


<input type="text" placeholder='Pesquisar:' style={{marginLeft:10}}/>
<button>
  <FontAwesomeIcon color='#fff' icon={faMagnifyingGlass}/>
</button>
</div>
</div>

               <div class="card-body">
                  <div class="row">
                     <div class="col-12">
                        <p>Lista de certidão</p>
                        <div>
                   
                        </div>
                        {this.state.data.certs.length > 0 ? <>
                           <table class="table table-sm table-bordered">
                           <thead>
                              <tr>
                                 <th scope="col">#</th>
                               
                                 <th scope="col">Tipo de documento</th>
                                 <th scope="col">Emonumento</th>
                              </tr>
                           </thead>
                           <tbody>

                           {this.state.data.certs.map((item,index)=><>
                           <tr>
                                 <td>{index + 1}</td>

                                 <td>{item.docEmt}</td>
                                 <td>{item.emn}</td>
                                 <td>
                                    <div class="btn-group btn-group-sm">
                                    <Link class="btn btn-success" to={`/${window.location.pathname.split("/")[1]}/preview/${item.token}`}><FontAwesomeIcon color='#fff' icon={faEye} /></Link>
                                    <Link class="btn btn-info" to={`/${window.location.pathname.split("/")[1]}/edit/${item.token}`}><FontAwesomeIcon color='#fff' icon={faPen} /></Link>
                                    <button class="btn btn-danger" onClick={()=>{
                                         Modal.create({
                                          titulo:"Tem certeza?",
                                          html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                          <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                           const id = toast.loading("Carregando...")
                                           fetch(`${apiUrl}${window.location.pathname}remover-certificado/${item.token}`, {
                                              method: 'DELETE',
                                              credentials: 'include',
                                              headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                              }
                                            }).then(response => {
                                            if(response.status == 200){
                                              toast.update(id, { render:"Certificado removido com sucesso!", type: "success", isLoading: false,autoClose: 3000});
          
                                              this.setState((prevState) => ({
                                                  data: {
                                                    ...prevState.data,
                                                    certs: prevState.data.certs.filter(i => i.id !== item.id)
                                                  }
                                                }));
                                            Modal.close()
          
                                            }
                                            })
          
          
          
                                          }}><i class="fas fa-download"></i> Remover </button></div>
                                          </>
                                      })
                                    }}> <FontAwesomeIcon icon={faTrash} /></button></div>
                                 </td>
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                        </> : <>
                        Não há dados cadastrados!
                        </>}
                       
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</main>
<div id="modal-root"></div>




<StaticDialog
        isOpen={this.state.edit}
        title="Editar cartório"
        onAfterClose={(result) => {
      this.setState({edit:false,item:{}})

        }}
    >

<div style={{padding:10}}>
   <div class="form-group mt-2"><label>Nome do Ofício:</label>
   <input type="text" class="form-control form-control-sm" 
   value={this.state.item.nome != null ? this.state.item.nome : ''} 
   name="nome"   onChange={this.handleChange2}/></div>



   <div class="form-group mt-2">
  <label>Endereço:</label>
   <input type="text" class="form-control form-control-sm" name="edrc"
       value={this.state.item.edrc != null ? this.state.item.edrc : ''} 
   onChange={this.handleChange2}/>
   </div>






   <div class="form-group mt-2"><label>Cidade/UF:</label>
   <input type="text" class="form-control form-control-sm" name="cidade"  
       value={this.state.item.cidade != null ? this.state.item.cidade: ''} 
   onChange={this.handleChange2}/> 
   </div>
   
   <div class="form-group mt-2">
  <label>Cns:</label>
   <input type="text" class="form-control form-control-sm" name="Cns"
       value={this.state.item.Cns != null ? this.state.item.Cns : ''} 
   onChange={this.handleChange2}/>
   </div>



   <div class="form-group mt-2">
    



<div class="form-group mt-2"><label>Serventia:</label>
   <input type="text" class="form-control form-control-sm" name="codeser"
    value={this.state.item.codeser != null ? this.state.item.codeser : ''} 
    onChange={this.handleChange2}/>
   </div>
   </div>
</div>
<hr/>

<div style={{display:'flex',justifyContent:'flex-end',marginBottom:20,marginRight:20}}>
<button className='btn btn-primary' onClick={(e)=>{
e.target.classList.add('load')

var cart = this.state.item;

fetch(`${apiUrl}${window.location.pathname}/atualizar-cartorio/${this.state.item.id}`, {
  method: 'PUT',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get("auth_token"),
  },
  body:JSON.stringify({
  nomeOfc:cart.nome,
  Edrc:cart.edrc,
  Cidade:cart.cidade,
  Cns:cart.Cns,
  codeser:cart.codeser
  })
}).then(response => {
if(response.status == 200){
  e.target.classList.remove('load')
 response.json().then(data=>{

 
  this.setState((prevState) => ({
      data: {
        ...prevState.data,
        cartorio: prevState.data.cartorio.map(i =>
          i.id === this.state.item.id ? { ...i,
          nome:cart.nome,
          edrc:cart.edrc,
          cidade:cart.cidade,
          Cns:cart.Cns,
          codeser:cart.codeser
      } : i
        )
      },
      edit:false,
      item:{}
    }));
toast.success('Atualizado!')
 })
 



}
})



  }}>
    <FontAwesomeIcon icon={faSave}/> Salvar
  </button>
</div>

    </StaticDialog>





<StaticDialog
        isOpen={this.state.modal}
        title="Adicionar cartório"
        onAfterClose={(result) => {
      this.setState({modal:false})
          // do something with dialog result
        }}
    >

<div style={{padding:10}}>
   <div class="form-group mt-2"><label>Nome do Ofício:</label>
   <input type="text" class="form-control form-control-sm" name="nome"   onChange={this.handleChange}/></div>



   <div class="form-group mt-2">
  <label>Endereço:</label>
   <input type="text" class="form-control form-control-sm" name="edrc"   onChange={this.handleChange}/>
   </div>





   <div class="form-group mt-2"><label>Cidade/UF:</label>
   <input type="text" class="form-control form-control-sm" name="cidade"    onChange={this.handleChange}/> 
   </div>
   

   <div class="form-group mt-2">
  <label>Cns:</label>
   <input type="text" class="form-control form-control-sm" name="Cns"   onChange={this.handleChange}/>
   </div>



   <div class="form-group mt-2">
    



<div class="form-group mt-2"><label>Serventia:</label>
   <input type="text" class="form-control form-control-sm" name="serventia" onChange={this.handleChange}/>
   </div>
   </div>
</div>
      <hr/>
     
<div style={{display:'flex',justifyContent:'flex-end',marginBottom:20,marginRight:20}}>
<button className='btn btn-primary' onClick={(e)=>{
e.target.classList.add('load')
var cart = this.state.cartorio;
fetch(`${apiUrl}${window.location.pathname}/adicionar-cartorio/`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get("auth_token"),
  },
  body:JSON.stringify({
  nomeOfc:cart.nome,
  Edrc:cart.edrc,
  Cidade:cart.cidade,
  Cns:cart.Cns,
  codeser:cart.serventia
  })
}).then(response => {
e.target.classList.remove('load')
if(response.status == 200){
 
  response.json().then(data=>{
    

      this.setState((prevState) => ({
          data: {
            ...prevState.data,
             cartorio: [...prevState.data.cartorio,{
              id:data.id,
              nome:cart.nome,
              edrc:cart.edrc,
              codeser:cart.serventia
            }],
          },
          modal:false
        }));
        toast.success('Salvo!')
 })
 



}
})
  }}>
    <FontAwesomeIcon icon={faSave}/> Salvar
  </button>
</div>
    </StaticDialog>



<ToastContainer/>
      </>
  
    );
  }
}

export default TjRs;